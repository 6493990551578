import React, { PureComponent } from 'react';
import { Widget } from '@typeform/embed-react';
import * as PropTypes from 'prop-types';

class Survey extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { data } = this.props;
    return (
      <Widget
        id={data.config.id}
        hidden={data.config.hidden_fields}
        style={{ width: '100%', height: '70vh' }}
        className="my-form"
      />
    );
  }
}

Survey.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default Survey;
